import {Card} from "react-bootstrap";
import {ApiPaginator} from "../../components/ApiWrapper";
import DataGridFilterable from "../../components/DataGridFilterable"
import ModalContextProvider from "../../context/ModalContext";
import {formatDateTime} from "../../utils/helper";
import PingCheckStatus from "../Sites/List/components/PingCheckStatus";
import FilterContextProvider from "../../context/DataGridFilterContext";
import FilterInput from "../../components/DataGridFilterable/FilterInput";
import FilterSelect from "../../components/DataGridFilterable/FilterSelect";

function DashboardPingCheckDomain({categories}) {

    const defaultFilters = {
        domain: '',
        category: '-'
    }

    function getColumns() {
        return [
            {
                key: 'domain',
                name: 'Домен',
                renderHeaderCell: (row) => <FilterInput row={row}/>
            },
            {
                key: 'category',
                name: 'Оффер',
                renderCell: ({row}) => row?.category,
                renderHeaderCell: (row) => <FilterSelect row={row} options={{0: '-', ...categories}}/>
            },
            {
                key: 'status',
                name: 'Статус',
                renderCell: ({row}) => <PingCheckStatus
                    key={row.id}
                    status={row.pingCheckHttpStatus}
                    pingAt={row.pingCheckAt}
                />
            },
            {
                key: 'pingcheckDate',
                name: 'Время последней проверки',
                sortable: true,
                renderCell: ({row}) => <div>{row.pingCheckAt ? formatDateTime(row.pingCheckAt) : ''}</div>
            }
        ]
    }

    return (
        <Card>
            <Card.Header>Проблемные домены</Card.Header>
            <Card.Body>
                <ModalContextProvider>
                    <FilterContextProvider defaultFilterValues={defaultFilters}>
                        <DataGridFilterable
                            defaultColDef={{flex: 1}}
                            columns={(props) => getColumns(props)}
                        />
                    </FilterContextProvider>
                    <ApiPaginator size="sm"/>
                </ModalContextProvider>
            </Card.Body>
        </Card>
    )
}

export default DashboardPingCheckDomain;
