import {Button, Modal} from "react-bootstrap";
import {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../context/ModalContext.js";
import {ApiWrapperContext} from "../../context/ApiWrapperContext";
import ApiManager from "../../api";
import CountdownButton from "../../components/buttons/CountdownButton";

function ApiConfirmModalApprovalDialog({title, children, approve, windowId}) {

    const {isOpen, closeModal, windowId: currentWindowId} = useContext(ModalContext);
    const {currentItem, fetchedData, route, routeParams, setLastFetchedDate, setCurrentPage} = useContext(ApiWrapperContext);
    const [confirmed, setConfirmed] = useState(false);
    const [approvalData, setApprovalData] = useState();

    const onClose = () => {
        closeModal(windowId);
    };

    const updatePage = () => {
        closeModal(windowId);

        const currentPage = fetchedData.paginator.currentPage;

        if (currentPage > 1 && fetchedData?.items?.length === 1) {
            setCurrentPage(currentPage - 1)
        } else {
            setLastFetchedDate(Date.now());
        }
    }

    const handleOnConfirm = async () => {

        const response = await new ApiManager().delete(route, currentItem.item, {
            id: currentItem.item.id,
            confirmed,
            ...routeParams
        })

        if (confirmed || response?.data?.message !== 'approve') {
            updatePage();
        } else {
            setApprovalData(response?.data);
            setConfirmed(true);
        }
    }

    useEffect(() => {
        if (isOpen) {
            setConfirmed(false);
        }
    }, [currentItem?.item?.id, windowId, isOpen])

    return (
        <Modal centered show={currentWindowId === windowId ? isOpen : false} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {confirmed ? (typeof approve === "function" ? approve(approvalData) : approve) : children}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="success" onClick={onClose}>Нет</Button>
                <CountdownButton variant="danger" initialTime={10} onClick={handleOnConfirm}>Да</CountdownButton>
            </Modal.Footer>
        </Modal>
    );
}

export default ApiConfirmModalApprovalDialog;
