import {ButtonGroup, Card} from "react-bootstrap";
import {ApiAddButton, ApiConfirmModalDialog, ApiPaginator} from "../../../components/ApiWrapper";
import ButtonTransfer from "../components/ButtonTransfer";
import ButtonHistory from "../components/ButtonHistory";
import ButtonEdit from "../../../components/buttons/ButtonEdit";
import ButtonDelete from "../../../components/buttons/ButtonDelete";
import {useContext, useEffect, useState} from "react";
import {ApiWrapperContext} from "../../../context/ApiWrapperContext";
import ApiManager from "../../../api";
import SitesListAddForm, {validationSchema} from "./SitesListAddForm";
import {ModalContext} from "../../../context/ModalContext";
import SitesListHistoryModal from "./SitesListHistoryModal";
import {formatDateTime} from "../../../utils/helper";
import SitesListManualTransferForm, {validationSchema as manualTransferValidationSchema} from "./SitesListManualTransferForm";
import ApiModalFormWrapper from "../../../components/ApiWrapper/ApiModalFormWrapper";
import styles from "../SiteCellBackground.module.css";
import FilterInput from "../../../components/DataGridFilterable/FilterInput";
import FilterContextProvider from "../../../context/DataGridFilterContext";
import DataGridFilterable from "../../../components/DataGridFilterable"
import ButtonTable from "../components/ButtonTable";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../../context/AuthContext";
import FilterSelect from "../../../components/DataGridFilterable/FilterSelect";
import ValidationStatus from "./ValidationStatus";
import DomainInfo from "./DomainInfo";
import ApplyWafRules from "./ApplyWafRules";
import ButtonGlueSite from "../components/ButtonGlueSite";
import GlueDomainsForm, {validationSchema as glueDomainValidationSchema} from "./GlueDomainsForm";
import registrars from "../../Registrars/registrars.json"
import ButtonCloudflareRedirect from "../components/ButtonCloudflareRedirect";
import SitesListRedirectModal from "./SitesListRedirectModal";
import {faSitemap, faSquareUpRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonCloudflareDns from "../components/ButtonCloudflareDns";
import SitesListDnsRecordModal from "./SitesListDnsRecordModal";
import {MultiModalContext} from "../../../context/MultiModalContext";
import PingCheckStatus from "./components/PingCheckStatus";

export default function SitesList({cloudflareProfile}) {

    const {setCurrentItem, setLastFetchedDate, route} = useContext(ApiWrapperContext)
    const {openModal} = useContext(ModalContext);

    const {openModal: openMultiModal} = useContext(MultiModalContext);

    const {currentUser} = useContext(AuthContext)
    const [serverProfile, setServerProfile] = useState(null);
    const [registrarProfile, setRegistrarProfile] = useState(null)
    const [wafGroups, setWafGroups] = useState([])
    const navigate = useNavigate();

    const defaultFilters = {
        domain: '',
        transferMode: '-',
        rknCheckStatus: '-',
        noteSticky: '',
        noteFloat: '',
        serverId: 0
    }

    function printServer(id) {
        if (!serverProfile) {
            return '-';
        }

        const server = serverProfile?.filter(data => data.id === id)?.shift();
        return server ? `${server?.title} (${server.ipAddress})` : '-'
    }

    function getServerOptionList() {
        const options = {0: '-'};

        if (!serverProfile) {
            return options;
        }

        return {
            ...options,
            ...Object.fromEntries(serverProfile.map(server => [server.id, `${server?.title} (${server.ipAddress})`]))
        }
    }

    function printTopDigit(top, row) {
        const topCurrent = row.domainStatistic[top]?.current ?? 0;
        const topPrevious = row.domainStatistic[top]?.previous ?? 0;
        const diff = topCurrent - topPrevious

        if (diff > 0) {
            return <div style={{display: 'inline-block'}}>{topCurrent}<i style={{color: 'green'}}>+{diff}</i></div>
        }

        if (diff < 0) {
            return <div style={{display: 'inline-block'}}>{topCurrent}<i style={{color: 'red'}}>{diff}</i></div>
        }

        return <div style={{display: 'inline-block'}}>{topCurrent}</div>
    }

    function printDomainStatistic(row) {
        return (
            <>{printTopDigit('top3', row)}/{printTopDigit('top10', row)}/{printTopDigit('top100', row)}</>
        )
    }

    function handleTransferModal(item) {
        setCurrentItem({
            item: { routeParams: { siteId: item.id } },
            isEdit: false
        })

        openModal('modalSiteTransferForm')
    }

    function handleGlueDomainModal(item)
    {
        setCurrentItem({
            item: { routeParams: { siteId: item.id } },
            isEdit: false
        })

        openModal('glueDomainModalForm')
    }

    function handleHistoryModal(item) {
        setCurrentItem({
            item,
            isEdit: false
        })

        openMultiModal('sitesHistoryModal', {siteId: item.id, domain: item.domain})
    }

    function handleMultiModal(modalId, payload) {
        openMultiModal(modalId, payload)
    }

    function getColumns({handleEdit, handleConfirmDelete}) {
        const columns = [];

        columns.push({
            key: 'transferMode',
            name: 'Режим',
            renderCell({row}) {
                return row.transferMode === 'manual' ?
                    <span title="Ручной" className="text-secondary fw-bolder">Р</span> :
                    <span title="Автомат" className="text-success fw-bolder">A</span>
            },
            renderHeaderCell: (row) => <FilterSelect row={row} options={{'': '-', manual: 'Р', auto: 'А'}}/>
        });

        columns.push({
            key: 'rknCheckStatus',
            name: 'РКН',
            width: 15,
            renderCell: ({row}) => <ValidationStatus
                key={row.id}
                id={row.id}
                rknCheckAt={row.rknCheckAt}
                status={row.rknCheckStatus}
            />,
            renderHeaderCell: (row) => <FilterSelect row={row} options={{
                '': '-',
                unchecked: 'Не проверен',
                valid: 'Ок',
                banned: 'Блокирован'
            }}/>
        });

        columns.push({
            key: 'hasWafRules',
            name: 'WAF',
            width: 25,
            renderCell: ({row}) => <ApplyWafRules row={row} groups={wafGroups} onSuccess={() => setLastFetchedDate(Date.now())}/>,
            renderHeaderCell: (row) => <FilterSelect row={row} options={{
                '': '-',
                yes: 'Да',
                no: 'Нет'
            }}/>
        })

        columns.push({
            key: 'hasCloudflareRedirects',
            name: 'CF Ред.',
            width: 25,
            renderCell: ({row}) => <FontAwesomeIcon className={
                row.hasCloudflareRedirects ?
                    row.isInheritOnTransferRedirects ?
                        'text-warning text-opacity-75' : 'text-success text-opacity-75'
                    : 'text-dark text-opacity-50'
            } icon={faSquareUpRight} />,
            renderHeaderCell: (row) => <FilterSelect row={row} options={{
                '': '-',
                yes: 'Да',
                no: 'Нет'
            }}/>
        })

        columns.push({
            key: 'hasCloudflareDnsRecords',
            name: 'CF NS',
            width: 25,
            renderCell: ({row}) => <FontAwesomeIcon className={
                row.hasCloudflareDnsRecords ?
                    row.isInheritOnTransferDnsRecords ?
                        'text-warning text-opacity-75' : 'text-success text-opacity-75'
                    : 'text-dark text-opacity-50'
            } icon={faSitemap} />,
            renderHeaderCell: (row) => <FilterSelect row={row} options={{
                '': '-',
                yes: 'Да',
                no: 'Нет'
            }}/>
        })

        columns.push({
            key: 'pingCheckStatus',
            name: 'ПингЧек',
            width: 70,
            renderCell: ({row}) => <PingCheckStatus
                key={row.id}
                status={row.pingCheckHttpStatus}
                pingAt={row.pingCheckAt}
            />
        })

        columns.push({
            key: 'domain',
            name: 'Домен',
            renderCell: ({row}) => <DomainInfo
                key={row.id}
                id={row.id}
                domainName={row.domain}
            />,
            renderHeaderCell: (row) => <FilterInput row={row}/>
        });

        if ((currentUser?.settings && currentUser.settings['global.isXmlFeedActive'] === '1')) {
            columns.push({
                key: 'domainStatistic',
                name: 'Топ 3/10/100',
                width: 200,
                renderCell: ({row}) => printDomainStatistic(row)
            })
        }

        columns.push({
            key: 'serverId',
            name: 'Сервер',
            renderCell: ({row}) => printServer(row.serverId),
            renderHeaderCell: (row) => <FilterSelect row={row} options={getServerOptionList()}/>
        });

        columns.push({
            key: 'noteSticky',
            name: 'Примечание (фиксированное)',
            width: 250,
            renderHeaderCell: (row) => <FilterInput row={row}/>
        });

        columns.push({
            key: 'noteFloat',
            name: 'Примечание (динамическое)',
            width: 250,
            renderHeaderCell: (row) => <FilterInput row={row}/>
        });

        columns.push({
            key: 'checkedAt',
            name: 'Переезд',
            sortable: true,
            renderCell: ({row}) => row.transferCheckAt && formatDateTime(row.transferCheckAt)
        });

        columns.push({
            key: 'action',
            cellClass: () => styles.cellTextRight,
            renderCell({row}) {
                return (
                    <ButtonGroup className="mb-2">
                        {(currentUser?.settings && currentUser.settings['global.isXmlFeedActive'] === '1') &&
                        <ButtonTable
                            tooltip="Показать статистику по сайту"
                            onClick={() => navigate(`/sites/${row.domain}/positions`)}
                        />
                        }
                        <ButtonCloudflareRedirect onClick={() => handleMultiModal(
                            'sitesListRedirectModal', {domainId: row.domainId, domain: row.domain, isChildren: false})
                        }/>
                        <ButtonCloudflareDns onClick={() => handleMultiModal(
                            'cloudflareDnsModal', {domainId: row.domainId, domain: row.domain, isChildren: false})
                        }/>
                        <ButtonGlueSite onClick={() => handleGlueDomainModal(row)}/>
                        <ButtonTransfer onClick={() => handleTransferModal(row)}/>
                        <ButtonHistory onClick={() => handleHistoryModal(row)}/>
                        <ButtonEdit onClick={() => handleEdit(row, 'sitesModalForm')}/>
                        <ButtonDelete onClick={() => handleConfirmDelete(row, 'sitesModalConfirm')}/>
                    </ButtonGroup>
                )
            }
        });

        return columns
    }

    useEffect(() => {
        const apiManager = new ApiManager();

        apiManager
            .request('server', {
                paginator: 'without'
            })
            .then(data => {
                setServerProfile(data)
            })

        apiManager
            .request('cloudflare.waf.group')
            .then(data => setWafGroups(data.data))

        apiManager
            .request('registrar', {
                paginator: 'without'
            })
            .then(data => {
                setRegistrarProfile(data)
            })
    }, [route])

    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    <div className="d-flex justify-content-between">
                        Сайты
                        <ApiAddButton size="sm" variant="outline-dark" modalId="sitesModalForm">Добавить</ApiAddButton>
                    </div>
                </Card.Title>
                <Card.Text>
                    <FilterContextProvider defaultFilterValues={defaultFilters}>
                        <DataGridFilterable
                            defaultColDef={{flex: 1}}
                            columns={(props) => getColumns(props)}
                        />
                    </FilterContextProvider>
                    <ApiPaginator size="sm"/>
                </Card.Text>
            </Card.Body>
            <ApiModalFormWrapper
                windowId="sitesModalForm"
                validationSchema={validationSchema}
                title="Изменение данных сайта"
            >
                <SitesListAddForm
                    serverProfile={serverProfile}
                    cloudflareProfile={cloudflareProfile}
                />
            </ApiModalFormWrapper>

            <ApiConfirmModalDialog windowId="sitesModalConfirm">
                <h3 className="text-center">Вы уверены что хотите удалить сайт?</h3>
                <p className="text-center text-danger">Будут так же удалены все данные по истории переездов.</p>
            </ApiConfirmModalDialog>


                <SitesListHistoryModal windowId="sitesHistoryModal"/>
                <SitesListRedirectModal windowId="sitesListRedirectModal"/>
                <SitesListDnsRecordModal windowId="cloudflareDnsModal"/>


            <ApiModalFormWrapper
                windowId="modalSiteTransferForm"
                validationSchema={manualTransferValidationSchema}
                title="Ручной переезд"
                targetRoute="sites.manual-transfer"
                saveButtonTitle="Переехать"
            >
                <SitesListManualTransferForm/>
            </ApiModalFormWrapper>

            <ApiModalFormWrapper
                windowId="glueDomainModalForm"
                validationSchema={glueDomainValidationSchema}
                title="Подклейка доменов"
                targetRoute="sites.site.glue-domain"
                saveButtonTitle="Запустить подклейку"
            >
                <GlueDomainsForm
                    registrarProfile={registrarProfile}
                    registrars={registrars}
                    cloudflareProfile={cloudflareProfile}
                />
            </ApiModalFormWrapper>
        </Card>
    )
}
