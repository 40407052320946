import DataGridWrapper from "./../DataGridWrapper";
import {useContext, useState} from "react";
import {FilterContext} from "../../context/DataGridFilterContext";
import {ApiWrapper} from "../ApiWrapper";

export default function ApiDataGridFilterWrapper({columns, enableSorting, ...rest}) {
    const {isFiltersEnabled, filters} = useContext(FilterContext);
    const [sortColumns, setSortColumns] = useState([]);

    const columnsForSorting = Object.fromEntries(sortColumns.map(column => [column.columnKey, column.direction]));
    return (
        <ApiWrapper routeData={{filters, sortBy: columnsForSorting}}>
            {(props) =>
                <DataGridWrapper
                    {...rest}
                    defaultColumnOptions={{
                        sortable: false
                    }}
                    columns={
                        columns(props)
                            .map((column) => ({
                                ...column,
                                headerCellClass: isFiltersEnabled ? 'filterCell' : undefined,
                            }))
                    }
                    rows={props.fetchedData.items}
                    sortColumns={sortColumns}
                    onSortColumnsChange={setSortColumns}
                    headerRowHeight={isFiltersEnabled ? 70 : undefined}
                />}
        </ApiWrapper>
    )
}
