import {ButtonGroup, Card, Table} from "react-bootstrap";
import {ApiWrapper, ApiPaginator, ApiAddButton, ApiConfirmModalApprovalDialog} from "../../../components/ApiWrapper";
import ApiWrapperContextProvider from "../../../context/ApiWrapperContext";
import ButtonEdit from "../../../components/buttons/ButtonEdit";
import ButtonDelete from "../../../components/buttons/ButtonDelete";
import ModalContextProvider from "../../../context/ModalContext";
import CloudFlareForm, {validationSchema} from "./CloudFlareForm";
import ApiModalFormWrapper from "../../../components/ApiWrapper/ApiModalFormWrapper";
import ApiManager from "../../../api";
import {useState, useEffect} from "react";
import CloudFlareBatchForm, {validationSchema as batchValidationSchema} from "./CloudFlareBatchForm";

function ApprovalText({domains}) {
    return <div className="text-center text-danger">
        <h2>Вы точно хотите удалить данный профиль?</h2>
        <strong> Он используется на {domains} доменах(е)</strong>
    </div>
}

export default function CloudFlare() {
    const [groups, setGroups] = useState([]);
    const [groupList, setGroupList] = useState({});

    useEffect(() => {
        new ApiManager()
            .request('cloudflare.group', {
                paginator: 'without'
            })
            .then(data => {
                setGroups(data)

                const list = {};
                for (const group of data) {
                    list[group.id] = group.title
                }

                setGroupList(list)
            })
    }, [])

    return (
        <ApiWrapperContextProvider route='cloudflare'>
            <ModalContextProvider>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <div className="d-flex justify-content-between">
                                Профили CloudFlare
                                <ButtonGroup className="mb-2">
                                    <ApiAddButton
                                        size="sm"
                                        variant="outline-dark"
                                        modalId="modalWithForm">
                                        Добавить
                                    </ApiAddButton>
                                    <ApiAddButton
                                        size="sm"
                                        variant="outline-dark"
                                        modalId="modalWithBatchForm">
                                        Добавить несколько
                                    </ApiAddButton>
                                </ButtonGroup>
                            </div>
                        </Card.Title>
                        <Card.Text>
                            <Table borderless responsive size="sm">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Профиль</th>
                                    <th>Email</th>
                                    <th>Группа</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ApiWrapper>
                                    {({
                                          fetchedData,
                                          handleEdit,
                                          handleConfirmDelete
                                      }) => fetchedData?.items?.map(item => (
                                        <tr key={item.id}>
                                            <td>
                                                {item.id}
                                            </td>
                                            <td>
                                                {item.title}
                                            </td>
                                            <td>
                                                {item.email}
                                            </td>
                                            <td>
                                                {groupList[item.group] ?? 'нет'}
                                            </td>
                                            <td width="20">
                                                <ButtonGroup className="mb-2">
                                                    <ButtonEdit onClick={() => handleEdit(item, 'modalWithForm')}/>
                                                    <ButtonDelete
                                                        onClick={() => handleConfirmDelete(item, 'modalConfirm')}/>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </ApiWrapper>
                                </tbody>
                            </Table>
                            <ApiPaginator/>
                        </Card.Text>
                    </Card.Body>
                </Card>

                <ApiModalFormWrapper
                    windowId="modalWithForm"
                    validationSchema={validationSchema}
                    title="Внесение данных аккаунта">
                    <CloudFlareForm groups={groups}/>
                </ApiModalFormWrapper>

                <ApiModalFormWrapper
                    targetRoute="cloudflare.batch-create"
                    windowId="modalWithBatchForm"
                    validationSchema={batchValidationSchema}
                    title="Массовое добавление аккаунтов Cloudflare">
                    <CloudFlareBatchForm groups={groups}/>
                </ApiModalFormWrapper>

                <ApiConfirmModalApprovalDialog
                    approve={approvalData => <ApprovalText domains={approvalData.domains}/>}
                    windowId="modalConfirm">
                    <h3 className="text-center">Вы уверены?</h3>
                </ApiConfirmModalApprovalDialog>
            </ModalContextProvider>
        </ApiWrapperContextProvider>
    )
}
