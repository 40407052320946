import {Container, NavDropdown as BootstrapNavDropdown, Nav, Navbar as BootstrapNavbar} from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import {useNavigate, useLocation} from "react-router-dom";
import {faBell, faGears, faKey, faRightFromBracket, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import NavDropdown from "./NavDropdown";

export default function Navbar() {
    const {handleLogout, currentUser} = useContext(AuthContext)
    const navigate = useNavigate();
    const location = useLocation();

    const [currentNavLink, setCurrentNavLink] = useState(location.pathname);

    return (
        <BootstrapNavbar bg="dark" variant="dark" expand="sm" className="mb-3">
            <Container fluid>
                <BootstrapNavbar.Toggle aria-controls="offcanvasNavbar-expand-sm"/>
                <BootstrapNavbar.Offcanvas
                    id="offcanvasNavbar-expand-sm"
                    aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id="offcanvasNavbarLabel-expand-sm">
                            Меню
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav
                            className="me-auto"
                            activeKey={currentNavLink}
                            onSelect={(selectedKey) => {
                                setCurrentNavLink(selectedKey)
                                navigate(selectedKey)
                            }}
                        >
                            <Nav.Item>
                                <Nav.Link eventKey='/'>Главная</Nav.Link>
                            </Nav.Item>
                            {(currentUser?.settings && currentUser.settings['global.isXmlFeedActive'] === '1') ?
                                <NavDropdown
                                    navItems={
                                        {
                                            ...{
                                                '/sites': 'Сайты и офферы',
                                                '/sites/domains': 'Все домены',
                                                '/sites/positions': 'Позиции сайтов',
                                                '/sites/positions/keyword': 'Ключевые слова'
                                            },
                                            // for admin user
                                            ...(
                                                currentUser.role === 'admin' ? {
                                                    '/cloudflare/waf': 'Правила WAF Cloudflare'
                                                } : {}
                                            )
                                        }
                                    }
                                    currentNavLink={currentNavLink}
                                    title='Сайты'
                                    key="site-nav-dropdown"
                                    id="site-nav-dropdown"
                                />
                                :
                                <NavDropdown
                                    navItems={
                                        {
                                            ...{
                                                '/sites': 'Сайты и офферы',
                                                '/sites/domains': 'Все домены'
                                            },
                                            // for admin user
                                            ...(
                                                currentUser.role === 'admin' ? {
                                                    '/cloudflare/waf': 'Правила WAF Cloudflare'
                                                } : {}
                                            )
                                        }
                                    }
                                    currentNavLink={currentNavLink}
                                    title='Сайты'
                                    key="site-nav-dropdown"
                                    id="site-nav-dropdown"
                                />
                            }

                            {currentUser.role === 'admin' &&
                            <Nav.Item>
                                <Nav.Link eventKey='/users'>Пользователи</Nav.Link>
                            </Nav.Item>
                            }
                            <NavDropdown
                                navItems={{
                                    '/cloudflare': 'CloudFlare',
                                    '/cloudflare/group': 'Группы CloudFlare',
                                    '/cloudflare/stat/category': 'Статистика по аккаунтам CloudFlare',
                                    '/registrars': 'Регистраторы',
                                    '/servers': 'Серверы',
                                }}
                                currentNavLink={currentNavLink}
                                title='Профили'
                                id="profile-nav-dropdown"
                                key="profile-nav-dropdown"
                            />
                            <NavDropdown
                                navItems={{
                                    '/domain/dns-update': 'Обновление DNS записей',
                                    '/domain/rkn-status': 'Проверка доменов',
                                }}
                                currentNavLink={currentNavLink}
                                title='Инструменты'
                                id="tools-nav-dropdown"
                                key="tools-nav-dropdown"
                            />
                            {currentUser.role === 'admin' && <>
                                <Nav.Item>
                                    <Nav.Link eventKey='/proxies'>Прокси</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey='/settings'>Настройки</Nav.Link>
                                </Nav.Item>
                                </>
                            }
                        </Nav>

                        <Nav
                            activeKey={currentNavLink}
                            onSelect={(selectedKey) => {
                                setCurrentNavLink(selectedKey)
                                navigate(selectedKey)
                            }}>
                            <BootstrapNavDropdown
                                align={{sm: 'end'}}
                                title={(
                                    <>
                                        <FontAwesomeIcon icon={faUser}/> {currentUser.userName}
                                    </>
                                )}
                                id="user-nav-dropdown">
                                <BootstrapNavDropdown.Item eventKey='/notification'>
                                    <FontAwesomeIcon icon={faBell}/> Уведомления
                                </BootstrapNavDropdown.Item>
                                <BootstrapNavDropdown.Item eventKey='/change-password'>
                                    <FontAwesomeIcon icon={faKey}/> Изменить пароль
                                </BootstrapNavDropdown.Item>
                                {(currentUser?.settings && currentUser.settings['global.isXmlFeedActive'] === '1') &&
                                <BootstrapNavDropdown.Item eventKey='/user-settings'>
                                    <FontAwesomeIcon icon={faGears}/> Настройки
                                </BootstrapNavDropdown.Item>
                                }
                                <BootstrapNavDropdown.Item onClick={handleLogout}>
                                    <FontAwesomeIcon icon={faRightFromBracket}/> Выйти
                                </BootstrapNavDropdown.Item>
                            </BootstrapNavDropdown>
                        </Nav>
                    </Offcanvas.Body>
                </BootstrapNavbar.Offcanvas>
            </Container>
        </BootstrapNavbar>
    )
}
