import {OverlayTrigger, Popover} from "react-bootstrap";
import {formatDateTime} from "../../../../utils/helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faCircleCheck, faCircleInfo, faCircleRight, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";

const statusMessages = {
    '200': 'OK',
    '201': 'Created',
    '202': 'Accepted',
    '203': 'Non-Authoritative Information',
    '204': 'No Content',
    '205': 'Reset Content',
    '206': 'Partial Content',
    '207': 'Multi-Status (WebDAV)',
    '208': 'Already Reported (WebDAV)',
    '226': 'IM Used',
    '300': 'Multiple Choices',
    '301': 'Moved Permanently',
    '302': 'Found',
    '303': 'See Other',
    '304': 'Not Modified',
    '305': 'Use Proxy',
    '306': '(Unused)',
    '307': 'Temporary Redirect',
    '308': 'Permanent Redirect (experimental)',
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '402': 'Payment Required',
    '403': 'Forbidden',
    '404': 'Not Found',
    '405': 'Method Not Allowed',
    '406': 'Not Acceptable',
    '407': 'Proxy Authentication Required',
    '408': 'Request Timeout',
    '409': 'Conflict',
    '410': 'Gone',
    '411': 'Length Required',
    '412': 'Precondition Failed',
    '413': 'Request Entity Too Large',
    '414': 'Request-URI Too Long',
    '415': 'Unsupported Media Type',
    '416': 'Requested Range Not Satisfiable',
    '417': 'Expectation Failed',
    '418': "I'm a teapot(RFC 2324)",
    '420': 'Enhance Your Calm (Twitter)',
    '422': 'Unprocessable Entity (WebDAV)',
    '423': 'Locked (WebDAV)',
    '424': 'Failed Dependency (WebDAV)',
    '425': 'Reserved for WebDAV',
    '426': 'Upgrade Required',
    '428': 'Precondition Required',
    '429': 'Too Many Requests',
    '431': 'Request Header Fields Too Large',
    '444': 'No Response (Nginx)',
    '449': 'Retry With (Microsoft)',
    '450': 'Blocked by Windows Parental Controls (Microsoft)',
    '451': 'Unavailable For Legal Reasons',
    '499': 'Client Closed Request (Nginx)',
    '500': 'Internal Server Error',
    '501': 'Not Implemented',
    '502': 'Bad Gateway',
    '503': 'Service Unavailable',
    '504': 'Gateway Timeout',
    '505': 'HTTP Version Not Supported',
    '506': 'Variant Also Negotiates (Experimental)',
    '507': 'Insufficient Storage (WebDAV)',
    '508': 'Loop Detected (WebDAV)',
    '509': 'Bandwidth Limit Exceeded (Apache)',
    '510': 'Not Extended',
    '511': 'Network Authentication Required',
    '598': 'Network read timeout error',
    '599': 'Network connect timeout error',
};

function PingCheckStatus({status, pingAt}) {
    const [showed, setShowed] = useState(false);

    const getStatusClass = (httpStatus) => {
        if (httpStatus >= 200 && httpStatus <= 299) return 'text-success text-opacity-75';
        if (httpStatus >= 300 && httpStatus <= 399) return 'text-info text-opacity-75';
        if (httpStatus >= 400 && httpStatus <= 499) return 'text-warning text-opacity-75';
        if (httpStatus >= 500 && httpStatus <= 599) return 'text-danger text-opacity-75';
        if (httpStatus === null) return 'text-dark text-opacity-50'
        return 'text-dark text-opacity-50';
    }

    const getStatusIcon = (httpStatus) => {
        if (httpStatus >= 200 && httpStatus <= 299) return faCircleCheck;
        if (httpStatus >= 300 && httpStatus <= 399) return faCircleRight;
        if (httpStatus >= 400 && httpStatus <= 499) return faCircleInfo;
        if (httpStatus >= 500 && httpStatus <= 599) return faCircleXmark;
        if (httpStatus === null) return faCircle;

        return faCircleXmark;
    }

    const getStatusText = (httpStatus) => {
        if (httpStatus >= 200 && httpStatus <= 299) return 'ОК';
        if (httpStatus === null) return 'Проверка не проводилась';
        if (httpStatus === 0) return 'DNS недоступен!';
        if (statusMessages[httpStatus] !== undefined) return statusMessages[httpStatus];
    }

    const popover = (
        <Popover id="popover-block">
            <Popover.Header as="h3">ПингЧек</Popover.Header>
            <Popover.Body>
                {status > 0 ? <p>Последняя проверка: {pingAt ? formatDateTime(pingAt) : '-'}</p> : ''}
                <p>{status > 0 ? status : ''} {getStatusText(status)}</p>
            </Popover.Body>
        </Popover>
    );

    return <OverlayTrigger trigger="hover" onToggle={() => setShowed(!showed)} placement="top" overlay={popover}>
        <div><FontAwesomeIcon
            className={getStatusClass(status)}
            icon={getStatusIcon(status)}/>
            <div>{status}</div></div>
    </OverlayTrigger>
}

export default PingCheckStatus
