import {formatDateTime} from "../../../utils/helper";
import {Card, Col, Row} from "react-bootstrap";
import ModalContextProvider from "../../../context/ModalContext";
import {ApiPaginator} from "../../../components/ApiWrapper";
import DataGridFilterable from "../../../components/DataGridFilterable";
import FilterContextProvider from "../../../context/DataGridFilterContext";
import FilterSelect from "../../../components/DataGridFilterable/FilterSelect";
import FilterInput from "../../../components/DataGridFilterable/FilterInput";
import {useEffect, useState} from "react";
import ApiManager from "../../../api";

function DomainList() {
    const [categories, setCategories] = useState([]);

    const statuses = {
        'mirror': 'Забаненное зеркало',
        'main': 'Основной',
        'backup': 'Резервный'
    };

    const rknStatuses = {
        'valid': 'Чист',
        'banned': 'Забанен',
        'unverified': 'Не проверен',
        'unbanned': 'Разбанен'
    }

    const defaultFilters = {
        domain: '',
        category: '-',
        rknStatus: '-',
        domainType: '-',
        expirationDate: '-',
        creationDate: '-'
    }

    useEffect(() => {
        const apiManager = new ApiManager();

        apiManager
            .request('sites.category', {
                paginator: 'without'
            })
            .then(data => {
                setCategories(data.data)
            })

    }, [])
    function getColumns() {
        return [
            {
                key: 'domain',
                name: 'Домен',
                sortable: true,
                renderHeaderCell: (row) => <FilterInput row={row}/>
            },
            {
                key: 'mainDomain',
                name: 'Основной домен',
                sortable: true,
                renderHeaderCell: (row) => <FilterInput row={row}/>
            },
            {
                key: 'category',
                name: 'Оффер',
                renderHeaderCell: (row) => <FilterSelect row={row} options={{
                    '' : '-',
                    ...Object.fromEntries(categories.map(category => [category.id, category.title]))
                }}/>
            },
            {
                key: 'rknStatus',
                name: 'Статус в базе РКН',
                renderCell: ({row}) => rknStatuses[row.rknStatus] ?? row.rknStatus,
                renderHeaderCell: (row) => <FilterSelect row={row} options={{
                    '' : '-',
                    ...rknStatuses
                }}/>
            },
            {
                key: 'domainType',
                name: 'Статус',
                renderCell: ({row}) => statuses[row.domainType] ?? row.domainType,
                renderHeaderCell: (row) => <FilterSelect row={row} options={{
                    '' : '-',
                    ...statuses
                }}/>
            },
            {
                key: 'expirationDate',
                name: 'Истекает',
                sortable: true,
                renderCell: ({row}) => {
                    if (!row.expirationDate) return (<div>-</div>);
                    const isExpired = Date.now() > Date.parse(row.expirationDate);
                    if (isExpired) {
                        return (<div style={{'color': 'red'}}>{formatDateTime(row.expirationDate)}</div>)
                    }

                    return (<div>{formatDateTime(row.expirationDate)}</div>)
                }
            },
            {
                key: 'creationDate',
                name: 'Создан',
                sortable: true,
                renderCell: ({row}) => {
                    if (!row.creationDate) return (<div>-</div>);
                    return (<div>{formatDateTime(row.creationDate)}</div>)
                }
            }
        ]
    }

    return (
        <Row>
            <Col xs={12}>
                <Card>
                    <Card.Header>Общий список доменов</Card.Header>
                    <Card.Body>
                        <Card.Title>Список доменов</Card.Title>
                        <ModalContextProvider>
                            <FilterContextProvider defaultFilterValues={defaultFilters}>
                                <DataGridFilterable
                                    defaultColDef={{flex: 1}}
                                    columns={(props) => getColumns(props)}
                                />
                            </FilterContextProvider>

                            <ApiPaginator size="sm"/>

                        </ModalContextProvider>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default DomainList
