import {ApiPaginator, ApiWrapper} from "../../../../components/ApiWrapper";
import DataGridWrapper from "../../../../components/DataGridWrapper";
import {formatDateTime} from "../../../../utils/helper";
import styles from "../../SiteCellBackground.module.css";
import ButtonDelete from "../../../../components/buttons/ButtonDelete";
import {Button, ButtonGroup, Modal} from "react-bootstrap";
import {useContext, useState} from "react";
import {ApiWrapperContext} from "../../../../context/ApiWrapperContext";
import ApiManager from "../../../../api";
import ButtonCloudflareDns from "../../components/ButtonCloudflareDns";
import {MultiModalContext} from "../../../../context/MultiModalContext";
import ButtonCloudflareRedirect from "../../components/ButtonCloudflareRedirect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSitemap, faSquareUpRight} from "@fortawesome/free-solid-svg-icons";

function SitesListHistory({siteId}) {

    const [isConfirmWindowOpen, setIsConfirmWindowOpen] = useState(false);
    const {openModal: openMultiModal} = useContext(MultiModalContext)
    const [currentItem, setCurrentItem] = useState(null);
    const {setLastFetchedDate} = useContext(ApiWrapperContext)

    function handleMultiModal(modalId, payload) {
        openMultiModal(modalId, payload)
    }

    function handleOnClose() {
        setIsConfirmWindowOpen(false);
        setCurrentItem(null)
    }

    function handleOnConfirm() {
        new ApiManager()
            .delete('sites.site.history', {}, {siteId, id: currentItem.id})
            .then(() => {
                handleOnClose();
                setLastFetchedDate(new Date())
            })
    }

    function handleConfirmDelete(row) {
        return () => {
            setIsConfirmWindowOpen(true);
            setCurrentItem(row);
        }
    }

    function getColumns() {
        return [
            {
                key: 'domain',
                name: 'Домен',
                width: '22%',
            },
            {
                key: 'hasCloudflareRules',
                name: 'CF Ред.',
                width: 25,
                renderCell: ({row}) => <FontAwesomeIcon className={row.hasCloudflareRules ? 'text-success text-opacity-75' : 'text-dark text-opacity-50'} icon={faSquareUpRight} />
            },
            {
                key: 'hasCloudflareDnsRecords',
                name: 'CF NS',
                width: 25,
                renderCell: ({row}) => <FontAwesomeIcon className={row.hasCloudflareDnsRecords ? 'text-success text-opacity-75' : 'text-dark text-opacity-50'} icon={faSitemap} />
            },
            {
                key: 'cloudflareEmail',
                name: 'Cloudflare Email',
                width: '15%',
            },
            {
                key: 'registrarName',
                name: 'Профиль регистратора',
                width: '15%',
            },
            {
                key: 'note',
                name: 'Примечание',
                width: '10%',
            },
            {
                key: 'transferAt',
                name: 'Дата переезда',
                width: '15%',
                renderCell({row}) {
                    return formatDateTime(row['transferAt'])
                }
            },
            {
                key: 'action',
                width: '10%',
                cellClass: () => styles.cellTextRight,
                renderCell({row}) {
                    return <ButtonGroup>
                        <ButtonCloudflareRedirect disabled={!row.hasCloudflareCredentials} onClick={() => handleMultiModal(
                            'sitesListRedirectModal', {domainId: row.backupDomainId, domain: row.domain, isChildren: true})
                        }/>
                        <ButtonCloudflareDns disabled={!row.hasCloudflareCredentials} onClick={() => handleMultiModal(
                            'cloudflareDnsModal', {domainId: row.backupDomainId, domain: row.domain, isChildren: true})
                        }/>
                        <ButtonDelete onClick={handleConfirmDelete(row)}/>
                    </ButtonGroup>
                }
            }
        ];
    }

    return <>
        <ApiWrapper>
            {(props) => <DataGridWrapper
                columns={getColumns(props)}
                rows={props.fetchedData.items}
            />}
        </ApiWrapper>

        <Modal backdrop="static" show={isConfirmWindowOpen} onHide={handleOnClose}>
            <Modal.Header closeButton>
                <Modal.Title>Подтверждение удаления</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h3 className="text-center">Вы уверены что хотите удалить домен из истории?</h3>
                <p className="text-center text-danger">Это действие нельзя отменить.</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="success" onClick={handleOnClose}>Нет</Button>
                <Button variant="danger" onClick={handleOnConfirm}>Да</Button>
            </Modal.Footer>
        </Modal>

        <ApiPaginator size="sm"/>
    </>
}

export default SitesListHistory;
