import {Card} from "react-bootstrap";
import {ApiPaginator} from "../../components/ApiWrapper";
import ModalContextProvider from "../../context/ModalContext";
import {formatDateTime} from "../../utils/helper";
import DataGridFilterable from "../../components/DataGridFilterable";
import FilterContextProvider from "../../context/DataGridFilterContext";
import FilterInput from "../../components/DataGridFilterable/FilterInput";
import FilterSelect from "../../components/DataGridFilterable/FilterSelect";

function DashboardBlockedDomain({categories}) {

    const defaultFilters = {
        domain: '',
        category: '-'
    }

    function getColumns() {
        return [
            {
                key: 'domain',
                name: 'Домен',
                renderHeaderCell: (row) => <FilterInput row={row}/>
            },
            {
                key: 'category',
                name: 'Оффер',
                renderCell: ({row}) => row.category.title,
                renderHeaderCell: (row) => <FilterSelect row={row} options={{0: '-', ...categories}}/>
            },
            {
                key: 'rknBannedDate',
                name: 'Дата блокировки',
                sortable: true,
                renderCell: ({row}) => formatDateTime(row.rknBannedAt)
            }
        ]
    }

    return (
        <Card>
            <Card.Header>Заблокированные домены</Card.Header>
            <Card.Body>
                <ModalContextProvider>
                    <FilterContextProvider defaultFilterValues={defaultFilters}>
                        <DataGridFilterable
                            defaultColDef={{flex: 1}}
                            columns={(props) => getColumns(props)}
                        />
                    </FilterContextProvider>
                    <ApiPaginator size="sm"/>
                </ModalContextProvider>
            </Card.Body>
        </Card>
    )
}

export default DashboardBlockedDomain;
