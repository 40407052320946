import {Button, Modal} from "react-bootstrap";
import {useContext} from "react";
import {MultiModalContext} from "../../../context/MultiModalContext";
import '../DisableBorder.css';
import SitesListHistory from "./SitesListHistory";
import ApiWrapperContextProvider from "../../../context/ApiWrapperContext";

export default function SitesListHistoryModal({windowId, ...rest}) {

    const {isModalOpen, closeModal, getModalPayload} = useContext(MultiModalContext)

    const handleClose = () => {
        closeModal(windowId);
    };

    return (
        <Modal {...rest}
               show={isModalOpen(windowId)}
               onHide={handleClose}
               fullscreen={true}
               aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    История переездов { getModalPayload(windowId).domain }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ApiWrapperContextProvider route='sites.site.history' routeParams={{siteId: getModalPayload(windowId)?.siteId }}>
                    <SitesListHistory siteId={getModalPayload(windowId)?.siteId}/>
                </ApiWrapperContextProvider>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    )
}
