import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Sites from "./pages/Sites";
import Registrars from "./pages/Registrars/Registrars";
import Servers from "./pages/Servers/Servers";
import Settings from "./pages/Settings/Settings";
import AppLayout from "./pages/Layout/AppLayout";
import LoginLayout from "./pages/Layout/LoginLayout";
import CloudFlare from "./pages/CloudFlare/Profiles";
import ApiWrapperContextProvider from "./context/ApiWrapperContext";
import ModalContextProvider from "./context/ModalContext";
import MultiModalContextProvider from "./context/MultiModalContext";
import UserList from "./pages/User";
import ChangePassword from "./pages/ChangePassword";
import Notification from "./pages/Notification";
import SitesPositionKeyword from "./pages/Sites/SitePositionKeyword/SitesPositionKeyword";
import SitesPosition from "./pages/Sites/SitesPosition";
import SitesDomainPosition from "./pages/Sites/SitesDomainPosition";
import UserSettings from "./pages/User/UserSettings";
import DomainList from "./pages/Sites/DomainList/DomainList";
import DnsUpdate from "./pages/DnsUpdate";
import RuleList from "./pages/CloudFlare/Waf";
import Proxies from "./pages/Crawler/Proxies";
import DomainStatus from "./pages/DomainStatus";
import Group from "./pages/CloudFlare/Group";
import CloudflareStatCategory from "./pages/CloudFlare/Stat/CloudflareStatCategory";

const routes = [
    {
        to: "/login",
        element: <LoginLayout/>,
        isPrivate: false,
        children: [
            {
                to: "",
                element: <Login/>
            },
        ]
    },
    {
        to: "/",
        element: <AppLayout/>,
        isPrivate: true,
        children: [
            {
                to: "/",
                element: <Dashboard/>,
            },
            {
                to: "/domain/dns-update",
                element: <DnsUpdate/>,
            },
            {
                to: "/domain/rkn-status",
                element: <DomainStatus/>,
            },
            {
                to: "/sites",
                element: (
                    <ApiWrapperContextProvider key='sites.category' route='sites.category' routeParams={{ paginator: 'without' }}>
                        <ModalContextProvider>
                            <MultiModalContextProvider>
                                <Sites/>
                            </MultiModalContextProvider>
                        </ModalContextProvider>
                    </ApiWrapperContextProvider>
                )
            },
            {
                to: "/sites/positions",
                element: (
                    <ApiWrapperContextProvider key='sites.category' route='sites.category' routeParams={{ paginator: 'without' }}>
                        <ModalContextProvider>
                            <SitesPosition/>
                        </ModalContextProvider>
                    </ApiWrapperContextProvider>
                )
            },
            {
                to: "/sites/:domain/positions",
                element: <SitesDomainPosition/>
            },
            {
                to: "/sites/positions/keyword",
                element: (
                    <ApiWrapperContextProvider key='sites.category' route='sites.category' routeParams={{ paginator: 'without' }}>
                        <ModalContextProvider>
                            <SitesPositionKeyword/>
                        </ModalContextProvider>
                    </ApiWrapperContextProvider>
                )
            },
            {
                to: "/cloudflare/waf",
                element: (
                    <ModalContextProvider>
                        <RuleList/>
                    </ModalContextProvider>
                )
            },
            {
                to: "/cloudflare/stat/category",
                element: <CloudflareStatCategory/>
            },
            {
                to: "/sites/domains",
                element: (
                    <ApiWrapperContextProvider key='site.domains' route='site.domains'>
                        <DomainList/>
                    </ApiWrapperContextProvider>
                )
            },
            {
                to: "/users",
                element: (
                    <ApiWrapperContextProvider key='user.list' route='user.list'>
                        <ModalContextProvider>
                            <UserList/>
                        </ModalContextProvider>
                    </ApiWrapperContextProvider>
                )
            },
            {
                to: "/cloudflare",
                element: <CloudFlare/>
            },
            {
                to: "/cloudflare/group",
                element: <Group/>
            },
            {
                to: "/proxies",
                element: <Proxies/>
            },
            {
                to: "/registrars",
                element: <Registrars/>
            },
            {
                to: "/servers",
                element: <Servers/>
            },
            {
                to: "/settings",
                element: <Settings/>
            },
            {
                to: "/user-settings",
                element: <UserSettings/>
            },
            {
                to: "/change-password",
                element: <ChangePassword/>
            },
            {
                to: "/notification",
                element: <Notification/>
            }
        ]
    }
];

export default routes
