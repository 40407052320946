import {Pagination as BootstrapPagination} from "react-bootstrap";
import {useContext} from "react";
import {ApiWrapperContext} from "../../context/ApiWrapperContext";

function Pagination({pages, currentPage, ...rest}) {
    return (
        <BootstrapPagination {...rest}>
            {
                [...Array(pages + 1).keys()].filter(number => number !== 0).map(number => (
                    <BootstrapPagination.Item key={number} active={number === currentPage}>
                        {number}
                    </BootstrapPagination.Item>
                ))
            }
        </BootstrapPagination>
    )
}

export default function ApiPaginator(props) {
    const {fetchedData, handlePageSelect} = useContext(ApiWrapperContext)

    return (
        <>
            {
                fetchedData?.paginator?.totalPages > 1 &&
                <Pagination
                    style={{
                        'margin-top': '20px',
                        'display': 'flex',
                        'flex-wrap': 'wrap',
                        'justify-content': 'start'
                    }}
                    pages={fetchedData?.paginator?.totalPages}
                    onClick={handlePageSelect}
                    currentPage={fetchedData?.paginator?.currentPage}
                    {...props}
                />
            }
        </>
    )
}
