import {createContext, useReducer} from "react";

export const MultiModalContext = createContext();

function MultiModalContextProvider(props) {

    const [modals, dispatch] = useReducer((state, action) => ({...state, ...action}), {})

    function closeModal(windowId, payload = {}) {

        if (modals[windowId] !== undefined) {
            dispatch({
                [windowId]: {
                    isOpen: false,
                    payload: {...modals[windowId].payload, ...payload}
                }
            })
        }
    }

    function openModal(windowId, payload = {}) {

        if (modals[windowId] !== undefined) {
            dispatch({
                [windowId]: {
                    isOpen: true,
                    payload: {...modals[windowId].payload, ...payload}
                }
            })
        } else {
            dispatch({
                [windowId]: {
                    isOpen: true,
                    payload: payload
                }
            })
        }
    }

    function isModalOpen(windowId) {

        if (modals[windowId] !== undefined) {
            return modals[windowId].isOpen;
        }

        return false;
    }

    function getModalPayload(windowId) {
        if (modals[windowId] !== undefined) {
            return modals[windowId].payload;
        }

        return {};
    }

    const contextValues = {
        closeModal,
        openModal,
        isModalOpen,
        getModalPayload
    }

    return (
        <MultiModalContext.Provider value={contextValues}>
            {props.children}
        </MultiModalContext.Provider>
    );
}

export default MultiModalContextProvider;
