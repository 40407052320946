import {Button} from "react-bootstrap";
import {useEffect, useState} from "react";

let timerInterval = null;

function CountdownButton({children, initialTime, onClick, ...rest}) {

    const [timeRemaining, setTimeRemaining] = useState(initialTime);
    const [isClicked, setIsClicked] = useState(false);

    const finishCountdown = () => {
        clearInterval(timerInterval);
        setTimeRemaining(initialTime);
        setIsClicked(false);
    }

    const handleClick = (e) => {
        setIsClicked(true);

        timerInterval = setInterval(() => {
            setTimeRemaining((prevTime) => {
                console.debug(prevTime);

                if (prevTime === 0) {
                    finishCountdown();
                    console.debug('Countdown complete!');
                    return 0;
                } else {
                    return prevTime - 1;
                }
            });
        }, 1000);

        if (typeof onClick === "function") {
            onClick(e)
        }
    }

    const isDisabled = isClicked && timeRemaining > 0;

    useEffect(() => () => finishCountdown(), []);

    return <Button disabled={isDisabled} onClick={handleClick} {...rest}>
        {children} {isDisabled && <>({timeRemaining})</>}
    </Button>
}

export default CountdownButton;
