import {Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRocket} from "@fortawesome/free-solid-svg-icons";
import ButtonOverlay from "../../components/buttons/ButtonOverlay";
import ApiManager from "../../api"
import {useEffect, useState} from "react";
import {formatDateTime} from "../../utils/helper";

function DashboardRknCheckInfo() {
    const [initialValues, setInitialValues] = useState({});
    const [isLaunchButtonPressed, setIsLaunchButtonPressed] = useState(false)

    const getLaunchIntervalLabel = () => {
        const intervalList = initialValues?.crawler?.launchIntervalList ?? {};
        return intervalList[initialValues?.crawler?.launchInterval]
    }

    const getCheckModeLabel = () => {
        const modeList = initialValues?.rknCheck?.modeList ?? {};
        return modeList[initialValues?.rknCheck?.mode];
    }

    const handleLaunchCheck = () => {
        new ApiManager()
            .create('dashboard.launch-check')
            .then(() => setIsLaunchButtonPressed(true))
    }

    useEffect(() => {
        new ApiManager()
            .request('settings')
            .then((values) => setInitialValues(values))
    }, [])

    return <Card>
        <Card.Header>РКН проверка</Card.Header>
        <Card.Body>
            <Card.Text>
                <div>
                    <strong>Режим:</strong> <span>{getCheckModeLabel()}</span>
                </div>
                <div>
                    <strong>Период запуска ПИНГ проверки:</strong> <span>{getLaunchIntervalLabel()}</span>
                </div>
                <div>
                    <strong>Последняя ПИНГ проверка:</strong> <span>{
                    initialValues?.rknCheck?.lastCrawlerRun ?
                        formatDateTime(initialValues.rknCheck?.lastCrawlerRun) :
                        '-'
                }</span>
                    <ButtonOverlay tooltip="Запустить проверку">
                        <Button
                            disabled={isLaunchButtonPressed}
                            style={{marginLeft: "4px"}}
                            size="sm"
                            onClick={handleLaunchCheck}
                            variant="outline-success"
                        >
                            <FontAwesomeIcon icon={faRocket}/>
                        </Button>
                    </ButtonOverlay>
                </div>
                <div>
                    <strong>Последнее обновление локального реестра РКН:</strong> <span>{
                    initialValues?.rknCheck?.lastDumpUpdateAt ?
                        formatDateTime(initialValues.rknCheck?.lastDumpUpdateAt) :
                        '-'
                }</span>
                </div>
                <div>
                    <strong>Баланс сервиса решения капчи:</strong> <span>
                    {initialValues?.crawler?.captchaSolverBalance} {initialValues?.crawler?.captchaSolverBalanceCurrency}
                </span>
                </div>
            </Card.Text>
        </Card.Body>
    </Card>
}

export default DashboardRknCheckInfo
