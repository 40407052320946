import DashboardExpiresDomain from "./DashboardExpiresDomain";
import ApiWrapperContextProvider from "../../context/ApiWrapperContext";
import DashboardRknCheckInfo from "./DashboardRknCheckInfo";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import DashboardBlockedDomain from "./DashboardBlockedDomain";
import DashboardPingCheckDomain from "./DashboardPingCheckDomain";
import ApiManager from "../../api";

function Dashboard() {
    const [categories, setCategories] = useState();
    const {currentUser} = useContext(AuthContext)

    useEffect(() => {
        new ApiManager()
            .request('sites.category', {
                paginator: 'without'
            })
            .then(response => {
                if (response.data) {
                    setCategories(Object.fromEntries(response?.data.map(category => [category.id, category.title])))
                }
            })
    }, [])

    return (
        <>
            {currentUser.role === 'admin' && <DashboardRknCheckInfo/>}

            <ApiWrapperContextProvider
                route='dashboard.expires-domains'
                routeParams={{
                    'type': 'expire',
                    'days': '30'
                }}>
                <DashboardExpiresDomain
                    title="Домены срок регистрации которых истекает в ближайшие 30 дней"
                    categories={categories}
                />
            </ApiWrapperContextProvider>
            <ApiWrapperContextProvider
                route='dashboard.expires-domains'
                routeParams={{
                    'type': 'delete',
                    'days': '30'
                }}>
                <DashboardExpiresDomain
                    title="Домены на удалении"
                    categories={categories}
                />
            </ApiWrapperContextProvider>
            <ApiWrapperContextProvider route='dashboard.pingcheck-domains'>
                <DashboardPingCheckDomain categories={categories}/>
            </ApiWrapperContextProvider>
            <ApiWrapperContextProvider route='dashboard.blocked-domains'>
                <DashboardBlockedDomain categories={categories}/>
            </ApiWrapperContextProvider>
        </>
    )
}

export default Dashboard;
