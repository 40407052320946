import {Button, ButtonGroup, Modal, Form as BootstrapForm} from "react-bootstrap";
import {useContext, useEffect, useRef, useState} from "react";
import ButtonOverlay from "../../../components/buttons/ButtonOverlay";
import {Record} from "./CloudflareDns"
import ApiManager from "../../../api";
import {Formik, Form, FieldArray} from 'formik';
import AlertDismissible from "../../../components/AlertDismissible";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import {MultiModalContext} from "../../../context/MultiModalContext";

const validationSchema = Yup.object().shape({
    records: Yup.array().of(Yup.object().shape({
            type: Yup.string(),
            name: Yup.string().required('Поле name не должно быть пустым'),
            content: Yup.string().when('type', {
                is: v => v !== 'URI',
                then: schema => schema.required('Поле content не должно быть пустым')
            }),
            isProxied: Yup.boolean(),
            priority: Yup.string().when('type', {
                is: v => v === 'URI',
                then: schema => schema.required('Поле Приоритет не должно быть пустым')
            }),
            weight: Yup.string().when('type', {
                is: v => v === 'URI',
                then: schema => schema.required('Поле Вес не должно быть пустым')
            }),
            target: Yup.string().when('type', {
                is: v => v === 'URI',
                then: schema => schema.required('Поле Цель не должно быть пустым')
            }),
        }
    ))
});

const routeName = 'sites.cloudflare.dns-record';

export default function SitesListDnsRecordModal({windowId, ...rest}) {

    const {isModalOpen, closeModal, getModalPayload} = useContext(MultiModalContext)
    const [records, setRecords] = useState([]);
    const [inheritOnTransfer, setInheritOnTransfer] = useState(true);

    const [submitStatus, setSubmitStatus] = useState(false);
    const [submitVariant, setSubmitVariant] = useState('success');
    const [submitMessage, setSubmitMessage] = useState('');

    const [isRecordsUpdating, setIsRecordsUpdating] = useState(false);

    const handleClose = () => {
        closeModal(windowId);
    };

    const formRef = useRef();

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    }

    const handleFormSubmit = (values) => {
        setIsRecordsUpdating(true);

        new ApiManager()
            .create(routeName, {...values, inheritOnTransfer}, {domainId: getModalPayload(windowId)?.domainId})
            .then(response => {
                if (response.data.message === 'success') {
                    setSubmitStatus(true)
                    setSubmitVariant('success')
                    setSubmitMessage(<><strong>Успешно.</strong> Записи сохранены</>)
                } else {
                    setSubmitStatus(true)
                    setSubmitVariant('danger')
                    setSubmitMessage(<><strong>Ошибка.</strong> {response.data.message.split('\n').map(line => <div>{line}</div>)}</>)
                }

                setIsRecordsUpdating(false);
            })
            .catch(() => setIsRecordsUpdating(false))
    }

    useEffect(() => {

        if (!isModalOpen(windowId)) {
            setRecords([]);
            return;
        }

        new ApiManager()
            .request(routeName, {domainId: getModalPayload(windowId)?.domainId})
            .then(response => {
                setRecords(response.data.records)
                setInheritOnTransfer(response.data.inheritOnTransfer)
            })
    }, [getModalPayload(windowId)?.domainId, isModalOpen(windowId), windowId])

    return (
        <Modal {...rest}
               show={isModalOpen(windowId)}
               onHide={handleClose}
               fullscreen={true}
               aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Редактирование DNS записей Cloudflare {getModalPayload(windowId)?.domain}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <AlertDismissible
                    show={submitStatus}
                    onClose={() => setSubmitStatus(false)}
                    variant={submitVariant}>
                    {submitMessage}
                </AlertDismissible>

                <Formik
                    innerRef={formRef}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    initialValues={{records}}
                    onSubmit={handleFormSubmit}
                >
                    {({values, handleSubmit}) => (
                        <Form onSubmit={handleSubmit}>
                            <FieldArray name="records">
                                {({remove, push}) => (
                                    <div>
                                        <Button
                                            onClick={() => push({
                                                type: 'A',
                                                name: '',
                                                content: '',
                                                isProxied: true
                                            })}
                                        >
                                            +
                                        </Button>

                                        {values.records.length > 0 && values.records.map((rule, index) => <Record
                                            key={index}
                                            index={index}
                                            place={index + 1}
                                            handleRemove={() => remove(index)}
                                        />)}
                                    </div>
                                )}
                            </FieldArray>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <div className="m-2">
                        <BootstrapForm.Check
                            type="switch"
                            disabled={!!getModalPayload(windowId)?.isChildren}
                            checked={inheritOnTransfer}
                            onChange={() => setInheritOnTransfer(!inheritOnTransfer)}
                            label="Копировать при переезде"
                        />
                    </div>
                    <ButtonOverlay tooltip="Применить и сохранить для текущего домена">
                        <Button disabled={isRecordsUpdating} onClick={handleSubmit} variant="info">
                            {isRecordsUpdating ? <><FontAwesomeIcon spin={true} icon={faSpinner} /> Ждите...</> : 'Применить & Сохранить'}
                        </Button>
                    </ButtonOverlay>
                    <ButtonOverlay tooltip="Закрыть текущее модальное окно">
                        <Button variant="danger" onClick={handleClose}>Закрыть</Button>
                    </ButtonOverlay>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    )
}
