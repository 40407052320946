const formatDateTime = (datetime) => {
    const date = new Date(datetime);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
};

const truncateString = (string, width) => string.length > width ? string.slice(0, width).trim() + "..." : string;

const shuffleString = (str, maxlength) => {
    var shuffledString = str.split('').sort(function () {
        return 0.5 - Math.random()
    }).join('');
    if (maxlength > 0) {
        shuffledString = shuffledString.substr(0, maxlength);
    }
    return shuffledString;
}

const generatePassword = (length, rules) => {
    if (!length) {
        length = 8;
    }

    if (!rules) {
        rules = [
            {chars: "abcdefghijklmnopqrstuvwxyz", min: 3},  // As least 3 lowercase letters
            {chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ", min: 2},  // At least 2 uppercase letters
            {chars: "0123456789", min: 2},                  // At least 2 digits
            {chars: "!@#$&*?|%+-_./:;=()[]{}", min: 1}      // At least 1 special char
        ];
    }

    var allChars = "", allMin = 0;
    rules.forEach(function (rule) {
        allChars += rule.chars;
        allMin += rule.min;
    });
    if (length < allMin) {
        length = allMin;
    }
    rules.push({chars: allChars, min: length - allMin});

    var pswd = "";
    rules.forEach(function (rule) {
        if (rule.min > 0) {
            pswd += shuffleString(rule.chars, rule.min);
        }
    });

    return shuffleString(pswd);
}

const profileGroupFactory = (items) => {

    const filterIds = items?.map(item => item.id);
    const filterValues = items?.map(item => item.title);

    return filterIds?.reduce((init, id, index) => ({...init, [id]: filterValues[index]}), {});
};

const formServerValidate = async (payload, actions) => {
    try {
        return await payload()
    } catch (e) {
        // 422 Unprocessable Content
        if (e.response.status === 422) {
            const errors = e.response?.data?.errors;
            Object.keys(errors).forEach(field => actions.setFieldError(field, errors[field][0]))
        } else {
            throw e
        }
    }
}

function inputStopPropagation(event) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.stopPropagation();
    }
}

function selectStopPropagation(event) {
    if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
        event.stopPropagation();
    }
}

function getCellStyleForValue(value, styles) {
    if (value >= 1 && value <= 3) {
        return styles.top3
    } else if (value >= 4 && value <= 10) {
        return styles.top10
    } else if (value >= 11 && value <= 20) {
        return styles.top20
    } else if (value >= 21 && value <= 50) {
        return styles.top50
    } else if (value >= 51) {
        return styles.top100
    }

    return styles.cell
}

export {
    inputStopPropagation,
    selectStopPropagation,
    formServerValidate,
    generatePassword,
    truncateString,
    formatDateTime,
    getCellStyleForValue,
    profileGroupFactory
}
