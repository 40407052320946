import {Card} from "react-bootstrap";
import {ApiPaginator} from "../../components/ApiWrapper";
import DataGridFilterable from "../../components/DataGridFilterable"
import ModalContextProvider from "../../context/ModalContext";
import {formatDateTime} from "../../utils/helper";
import FilterSelect from "../../components/DataGridFilterable/FilterSelect";
import FilterContextProvider from "../../context/DataGridFilterContext";
import FilterInput from "../../components/DataGridFilterable/FilterInput";

function DashboardExpiresDomain({title, categories}) {

    const defaultFilters = {
        domain: '',
        category: '-',
        domainType: '-'
    }

    const statuses = {
        'mirror': 'Забаненное зеркало',
        'main': 'Основной',
        'backup': 'Резервный'
    };

    function getColumns() {
        return [
            {
                key: 'domain',
                name: 'Домен',
                renderHeaderCell: (row) => <FilterInput row={row}/>
            },
            {
                key: 'domainType',
                name: 'Статус',
                renderCell: ({row}) => statuses[row.domainType] ?? '',
                renderHeaderCell: (row) => <FilterSelect row={row} options={{'': '-', ...statuses}}/>
            },
            {
                key: 'category',
                name: 'Оффер',
                renderCell: ({row}) => row?.category?.title,
                renderHeaderCell: (row) => <FilterSelect row={row} options={{0: '-', ...categories}}/>
            },
            {
                key: 'expirationDate',
                name: 'Истекает',
                sortable: true,
                renderCell: ({row}) => <div>{formatDateTime(row.expirationDate)}</div>
            }
        ]
    }

    return (
        <Card>
            <Card.Header>{title}</Card.Header>
            <Card.Body>
                <ModalContextProvider>
                    <FilterContextProvider defaultFilterValues={defaultFilters}>
                       <DataGridFilterable
                                defaultColDef={{flex: 1}}
                                columns={(props) => getColumns(props)}
                       />
                    </FilterContextProvider>
                    <ApiPaginator size="sm"/>
                </ModalContextProvider>
            </Card.Body>
        </Card>
    )
}

export default DashboardExpiresDomain;
