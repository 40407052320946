import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../../../components/buttons/Button.js"

function ButtonSettings({onClick}) {
    return (
        <BaseButton placement="top" tooltip="Настройки">
            <Button size="sm" onClick={onClick} variant="outline-dark">
                <FontAwesomeIcon icon={faGear}/>
            </Button>
        </BaseButton>
    );
}

export default ButtonSettings;
