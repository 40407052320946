import {useCallback, useEffect, useState} from "react";
import ApiManager from "../../../api";
import {Card} from "react-bootstrap";
import DataGridWrapper from "../../../components/DataGridWrapper";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function CloudflareStatCategory() {
    const [columns, setColumns] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [rows, setRows] = useState([]);

    const data = useCallback(async () => {
        const apiManager = new ApiManager();
        return await apiManager.request('cloudflare.stat.category');
    }, [])

    useEffect(() => {
        setIsLoaded(false);

        data().then(({data}) => {
            const rows = []
            const columns = [
                {
                    key: 'email',
                    name: 'Аккаунт/Оффер',
                    frozen: true
                },
            ];

            data.categories.forEach((category) => columns.push({
                key: category.title + category.id,
                name: category.title
            }));

            Object.keys(data.domainQuantity).forEach(
                (email) => {
                    const row = {
                        email
                    }

                    data.domainQuantity[email].forEach((item) => row[item.category.title + item.category.id] = item.totalCount)

                    rows.push(row)
                }
            )

            setColumns(columns);
            setRows(rows);
            setIsLoaded(true);
        })

    }, [data])


    return (
        <Card>
            <Card.Header>Статистика по аккаунтам CloudFlare</Card.Header>
            <Card.Body>
                <Card.Title>Количество доменов на аккаунте</Card.Title>
                <Card.Text>
                    {!isLoaded ? <div className="d-flex flex-row justify-content-center">
                        <div style={{'width': '200px'}}>
                            <FontAwesomeIcon spin={true} size={"xl"} icon={faSpinner} /> <strong>Сбор данных....</strong>
                        </div>
                    </div> :
                        <DataGridWrapper
                            columns={columns}
                            rows={rows}
                        />}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default CloudflareStatCategory
